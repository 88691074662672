<template>
  <section>
    <div class="container">
      <AddToBasketBtn class="basketBtn" :item="item" />
      <h1>{{ item.title }}</h1>
      <p>Schema</p>
      <div class="preview" v-html="item.description"></div>
      <div class="pdf-info-container">
        <div class="pdf-info">
           Das vollständige Schema findest Du auf der heruntergeladenen PDF.
        </div>
</div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
/* Vue Css Loader Magic (to access the v-html content) */
.preview {
  white-space: pre-line;
  background: #fff;
  padding: 4em;
  border-radius: 7px;
  box-shadow: 0 10px 70px rgba(0,0,0,.04);

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  
  @media screen and (max-width: 640px) {
    padding: 1em;
  }
    &::v-deep a {
    text-decoration: none;
    color: #4285f4;
  }

  &::v-deep h1 {
    margin: 0px;
    word-wrap: break-word;
  }

  &::v-deep p {
    margin: 0px;
  }

  &::v-deep b {
    margin-bottom: 1em;
  }

  &::v-deep span {
    display: block;
  }

}

</style>

<style lang="scss" scoped>
@import '../assets/css/_variables';

section {
  max-width: 800px;
  margin: 0px auto;
  padding: 4em 8em;
  display: flex;
  justify-content: center;
  color: $darkBlue;
  letter-spacing: 0.01em;
  line-height: 30px;
  
  @media screen and (max-width: 992px) {
    padding: 4em 4em;
  }
  
  @media screen and (max-width: 640px) {
    padding: 1em;
  }

  div {
    position: relative;
  }
}
  
.container {
  max-width: 800px;
  border-radius: 7px;
  margin-bottom: 60px;

  h1 {
    margin-top: 1em;
    line-height: 1.5;
    max-width: 730px;
    margin-left: 35px;
    margin-bottom: 10px;
    overflow-wrap: anywhere;
    font-size: 2.5em;
    
    @media screen and (max-width: 640px) {
    font-size: 1.7em;
    margin-left: 15px;
  }
    
  }
  p {
    max-width: 730px;
    margin: 0px 0px 35px 35px;
    font-weight: bold;
    opacity: 0.4;
    
    
  @media screen and (max-width: 640px) {
    margin-left: 15px;
    }
  }
}

.basketBtn {
  position: absolute;
  right: 45px;
  top: 80px;
  z-index: 2;

  @media screen and (max-width: 640px) {
    right: 17px;
    top: 40px;
  }
}
.pdf-info-container {
  margin-top: 1em;
  white-space: pre-line;
  background: #fff;
  padding: 2em;
  border-radius: 7px;
  box-shadow: 0 10px 70px rgba(0,0,0,.04);
}

.pdf-info {
  &::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 4px;
    border-radius: 50%;
    background-color: rgb(26, 183, 21); // Green color indicating positive info
  }
  padding: 0.5em;
  border-radius: 5px;
}

</style>

<script>
// import axios from 'axios';
import AddToBasketBtn from '../components/AddToBasketBtn.vue';

export default {
  name: 'SchemePreview',
  components: {
    AddToBasketBtn,
  },
  data() {
    return {
      item: null,
      parsedPreview: '',
      loader: null,
    };
  },
  methods: {
    parseCustomTags(line, formatTag, htmlTag) {
      const parts = line.split(formatTag);
      if (parts.length > 1) {
        line = parts[0];
        for (let i = 1; i < parts.length; i++) {
          line += (i % 2 == 1 ? `<${htmlTag}>` : `</${htmlTag}>`) + parts[i];
        }
        if (parts.length % 2 == 0) {
          line += `</${htmlTag}>`;
        }
      }
      return line;
    },
  },
  async mounted() {
    this.loader = this.$loading.show({});
    // this.item = (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/getPDF?id=${this.$route.params.id}`)).data;
    this.item = await this.$api
      .get(`/schemata`, {
        params: {
          filters: {
            slug: {
              $eq: this.$route.params.id,
            },
          },
        },
      })
      .then((response) => {
        const transformedData = this.$findOne.transform(response.data);
        console.log(transformedData);
        transformedData.type = 'schema';
        return transformedData;
      });

    this.loader.hide();

    const parsedLines = [];

    //Parse Document Description Format
    const lines = this.item.description.split('\n');
    for (let i in lines) {
      let line = lines[i];

      if (line == '') {
        parsedLines.push('<br/>');
        continue;
      }

      if (i == 0) {
        line = this.parseCustomTags(line, '%', 'h1');
      } else {
        line = this.parseCustomTags(line, '%', 'b');
      }
      line = this.parseCustomTags(line, '&', 'i');

      //line = line.replace("TABTAB", `<span style="margin-left: 2em; margin-right: 0.25em;"></span>`);
      //line = line.replace("TAB", `<span style="margin-left: 1em; margin-right: 0.25em;"></span>`);
      if (line.startsWith('TAB')) {
        const twoTabs = line.startsWith('TABTAB');
        line = line.replace('TAB', '').replace('TAB', '');
        line = `<p style="margin-left: ${twoTabs ? 2 : 1}em">${line}</p>`;
      } else {
        const hasMargin = line.includes('<b>');
        line = `<span ${
          hasMargin ? 'style="margin-bottom: 0.25em"' : ''
        }>${line}</span>`;
      }

      parsedLines.push(line);
    }
    this.parsedPreview = parsedLines.join('\n');
  },
};
</script>
