<template>
  <div>
    <section class="section-1">
      <div class="case_ads">
        <h1>69 Fälle</h1>
        <span class="badge">Neu</span>
        
      </div>
    
      <div class="section-2">
        <p>Fälle zu lösen ist nicht nur <b>wichtig</b>, um es in den <b>Klausuren leichter</b> zu haben, sondern auch, um <b>Problembewusstsein aktiv</b> zu lernen. Alle unsere Fälle bestehen aus <b>Sachverhalt, einer gutachterlich formulierten Musterlösung</b> und einem <b>Quiz mit fünf Wiederholungsfragen</b>. Los geht's.</p>
        <FullHierarchySearch type="cases" :hideFilters="true" />
      </div>
    </section>

    <HomeContent />
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/css/_variables';

h1,
h2 {
  margin: 60px 0px;
  font-size: 3em;
  text-align: center;
  color: $darkBlue;

  @media screen and (max-width: 640px) {
    font-size: 1.7em;
    margin: 15px 0px;
  }
}

.section-2 {
    max-width: 840px;
    margin: 0px auto;
  }

.section-2 p {
    color: #002060;
    font-size: 21px;
    padding: 0em 1em;

      @media screen and (max-width: 640px) {
      font-size: 16px;
      padding: 0em 1em;
      }
  
  }
  
.section-2 div {
    margin-bottom: 100px;
  }

.case_ads{
    display: flex;
    text-align: center;
    align-items: center;
    margin: 0px auto;
    max-width: 270px;
    /* Sonst 206px ohne Angabe der Zahl */
    
    @media screen and (max-width: 640px) {
    max-width: 135px;
  }
}

.blue {
  color: $blue;
  display: block;
}

.badge {
  color: white;
  background-color: #6fc276;
  border-radius: 5px;
  margin-left: 10px;
  display: inline-block;
  font-size: 1.5em;
  padding: 8px 15px;
  font-weight: bold;
  
  @media screen and (max-width: 640px) {
    font-size: 1.2em;
    padding: 4px 10px;
  }
}

</style>

<script>
import FullHierarchySearch from '../components/FullHierarchySearch.vue';
import HomeContent from '../components/HomeContent.vue';

export default {
  name: 'CasesFullSearch',
  components: {
    FullHierarchySearch,
    HomeContent,
  },
};
</script>
