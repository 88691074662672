<template>
  <div>
    <section class="section-1">
      <h1>Schematische Suche</h1>
      <p>Unsere Schematische Suche hat <b>zwei große Vorteile</b>: Zum einen kannst Du dich ganz einfach durch unsere <b>gesamten Lerninhalte</b> klicken und nach Definitionen und Schemata suchen. Das ist insbesondere von Vorteil, wenn Du in unserer klassischen Suchleiste nicht fündig geworden bist. Zum anderen sind alle Definitionen und Schemata <b>übersichtlich gegliedert</b>, sodass Du mit einem Blick Zusammenhänge, Gemeinsamkeiten und Unterschiede zwischen Normen erkennst.</p>
      <div>
        <FullHierarchySearch />
      </div>
    </section>

    <HomeContent />
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/css/_variables";

h1,
h2 {
  margin: 60px 0px;
  font-size: 3.0em;
  text-align: center;
  color: $darkBlue;

  @media screen and (max-width: 640px) {
    font-size: 1.7em;
    margin: 15px 0px;
  }
}

.blue {
  color: $blue;
  display: block;
}

.section-1 {
    max-width: 840px;
    margin: 0px auto;
  }

.section-1 p {
    color: #002060;
    font-size: 21px;
    padding: 0em 1em;

      @media screen and (max-width: 640px) {
      font-size: 16px;
      padding: 0em 1em;
      }
  }
  
.section-1 div {
    margin-bottom: 100px;   
  }
</style>

<script>
import FullHierarchySearch from "../components/FullHierarchySearch.vue";
import HomeContent from "../components/HomeContent.vue";

export default {
  name: "FullSearch",
  components: {
    FullHierarchySearch,
    HomeContent,
  },
};
</script>
