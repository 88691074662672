<template>
  <section>
    <h1>
      Per
      <span class="blue">Drag &amp; Drop </span> individualisieren. <br /><span
        class="blue"
        >Dann als Skript downladen.</span>
    </h1>
    <div v-if="this.$store.state.basket.items.length">
      <div class="center">
        <Draggable
          v-model="basketItems"
          group="items"
          @start="drag = true"
          @end="drag = false"
          ghost-class="ghost"
        >
          <BasketItem
            v-for="(item, index) in basketItems"
            :key="item.id"
            :data="item"
            :index="index"
          />
        </Draggable>
      </div>
      <div class="downloadBtn">
        <button @click="downloadPDF" :disabled="loading">Download</button>
      </div>
      <span class="tokensReminder" v-if="user"
        >Du kannst noch {{ user.tokens }} Lerneinheiten herunterladen ✌️.</span
      >
    </div>
    <div v-else>
      <h2>Dein Warenkorb ist leer</h2>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '../assets/css/_variables';

section {
  margin-bottom: 1em;
  padding: 1em;

  span {
    color: $blue;
  }

  h1 {
    margin-bottom: 60px;
    font-size: 3em;
    text-align: center;
    color: $darkBlue;

    @media screen and (max-width: 640px) {
      font-size: 1.7em;
    }
  }

  h2 {
    text-align: center;
    color: $darkBlue;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .downloadBtn {
    display: flex;
    justify-content: center;
    margin-bottom: 0.2em;

    button:disabled {
      opacity: 0.6;
    }
  }

  .tokensReminder {
    display: flex;
    justify-content: center;
    color: $darkBlue;
  }
}
</style>

<script>
import Draggable from 'vuedraggable';
import Bowser from "bowser";
import BasketItem from '../components/BasketItem.vue';
import { downloadB64PDF } from '../assets/js/util';
import config from '@/config';


export default {
  name: 'Basket',
  components: {
    Draggable,
    BasketItem,
  },
  data() {
    return {
      loading: false,
      loader: null,
    };
  },
  methods: {
    async downloadPDF() {
      console.log('downloadPDF triggered'); // Debugging log
      if (this.loading) return;

      // if (this.$store.state.downloadCount >= 20) {
      //   this.$toast.open({
      //     message: 'Maximale Downloads in der aktuellen Beta-Phase erreicht',
      //     type: 'error',
      //   });
      //   return;
      // }

      // this.loader = this.$loading.show({});
      this.loading = true;
      console.log('requesting pdf...');

      // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const browser = Bowser.getParser(window.navigator.userAgent);
      const isSafari = browser.satisfies({safari: ">=0"});

      if (isSafari) {
        console.log("This is Safari");
      } else {
        console.log("This is not Safari");
      }
      
      // console.log('SAFARI:',navigator.userAgent);
      // const isSafari = true

      try {
        const response = await this.$api.post(
          '/buildPDF',
          {
            items: this.$store.state.basket.items.map((i) => ({
              id: i.id,
              type: i.type,
            })),
            legacyDownload: isSafari,
          },
          {
            customData: {
              userToken:
                this.$store.state.user && this.$store.state.user.jwt
                  ? this.$store.state.user.jwt
                  : '', // Check if the token exists before adding it to the request
            },
          }
        );

        const data = response.data;

        // this.loader.hide();

        if (data.success) {
          this.$store.commit('updateBasket', []);
          console.log('got pdf');
          this.$toast.open('PDF erfolgreich erstellt');

          this.$store.commit(
            'setDownloadCount',
            this.$store.state.downloadCount +
              this.$store.state.basket.items.length
          );

          if (isSafari) {
            let url = `${config.strapiURL}/temp/${data.fileName}`;
            window.location.href = url
          } else {
            downloadB64PDF(data.file, 'HeyJura_Download.pdf');
          }
        } else {
          // this.loader.hide();
          this.$toast.open({
            message: 'Erstellen der PDF fehlgeschlagen',
            type: 'error',
          });
        }
      } catch (error) {
        console.error('Error in downloadPDF:', error);

        let message = 'Erstellen der PDF fehlgeschlagen'; // Default error message
        let redirectTo = null;

        if (error.response) {
          if (error.response.status === 401) {
            // 401: Unauthorized. Redirect to dashboard without showing a toast.
            redirectTo = '/dashboard';
          } else if (error.response.status === 403) {
            // 403: Terms not agreed. Show error toast and redirect to dashboard.
            message =
              'Du musst den AGB zustimmen, bevor Du die PDF herunterladen kannst.';
            redirectTo = '/dashboard';
          } else if (error.response.status === 402) {
            // 402: Not enough tokens. Show error toast and redirect to /bundles.
            message =
              'Nicht genügend Tokens. Bitte füge mehr Tokens hinzu, um das PDF herunterzuladen.';
            redirectTo = '/bundles';
          }
        }

        // this.loader.hide();

        // Show the error message to the user, unless it's a 401 error (unauthorized)
        if (error.response.status !== 401 && error.response.status !== 403) {
          this.$toast.open({
            message,
            type: 'error',
          });
        }

        // If a redirection path was set, redirect to that path after a delay
        if (redirectTo) {
          if (error.response.status !== 401 && error.response.status !== 403) {
            setTimeout(() => {
              this.$router.push({ path: redirectTo });
            }, 3000);
          } else {
            this.$router.push({ path: redirectTo });
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    basketItems: {
      get() {
        return this.$store.state.basket.items;
      },
      set(value) {
        this.$store.commit('updateBasket', value);
      },
    },
  },
};
</script>
