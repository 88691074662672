<template>
  <footer>
    <div class="flex">
      <div class="text">
        <h2>Jura wird einfacher</h2>
        <p>
          Wir sind eine Online-Lernplattform, die Jurastudent:innen
          Definitionen und Schemata vom 1. Semester bis zum 1. Staatsexamen
          bereitstellt! Selbstverständlich wird unser Lernangebot, welches von Expert:innen erstellt wurde, stetig
          erweitert. Zur Zeit bieten wir folgende Lerninhalte an: BGB AT,
          Schuldrecht AT, Schuldrecht BT, Sachenrecht, Familienrecht, Handels- und Gesellschaftsrecht, ZPO, Grundrechte,
          Verfassungsprozessrecht, Verwaltungsprozessrecht, Europarecht, Baurecht, Staatshaftungsrecht, Polizeirecht, Strafrecht AT, Strafrecht BT und StPO. Auch bieten wir Fälle aus dem BGB AT, dem Sachenrecht und dem Strafrecht BT 1 und BT2 in Kooperation mit dem Fall Fallag an. Dabei ist uns wichtig, dass wir selbstverständlich auf hohem
          wissenschaftlichen Niveau arbeiten, unsere Lerninhalte dennoch auf
          Anhieb verständlich sind. Das erspart Stress und Arbeit, denn
          studieren kann auch einfacher sein. Suche Dir Deine Lerninhalte zusammen,
          sortiere sie nach eigener Präferenz und lade alle Unterlagen in wenigen Sekunden als eine
          geschlossenen PDF herunter, die Du Dir ausdrucken kannst oder sie beispielsweise mit dem IPad bearbeitest. So geht Jura.
        </p>
      </div>
      <div class="logo">
        <img src="../assets/img/LogoLow.png" alt="heyJura Logo" />
      </div>
    </div>
    <div class="links">
      <span>
        <router-link to="/imprint">Impressum</router-link>
      </span>
      <span>
        <router-link to="/goals">Unser Ziel</router-link>
      </span>
      <span>
        <router-link to="/contact">Kontakt</router-link>
      </span>
      <span>
        <a href="https://tally.so/r/mZVkGe" target="_blank">✨ Feedback</a>
      </span>
      

      <!--
      <span>
        <router-link to="/copyright">Urheberrecht</router-link>
      </span>
      -->

      <!--
      <span>Quellen</span>
      -->

      <span>
        <router-link to="/privacy">Datenschutz</router-link>
      </span>
      <span>
        <a target="__blank" href="https://www.instagram.com/heyjura.de/">
          <img src="../assets/img/instagram.png" alt="Instagram" />
        </a>
      </span>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
@import '../assets/css/_variables';
footer {
  padding: 10px 40px;
  @media screen and (max-width: 640px) {
    padding: 10px 2em;
  }
  background: #f5f7ff;
  color: $darkBlue;
  .flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 100px;
  }
  .text {
    max-width: 800px;
    @media screen and (max-width: 640px) {
      max-width: 100%;
    }
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    img {
      max-width: 128px;
    }
    @media screen and (max-width: 640px) {
      display: none;
    }
  }
  .links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    span {
      margin-right: 24px;
      font-weight: bold;
      cursor: pointer;
      @media screen and (max-width: 640px) {
        font-size: 14px;
      }
      a {
        text-decoration: none;
        color: $darkBlue;
      }
    }
    img {
      width: 1.5em;
    }
  }
  @media screen and (max-width: 640px) {
    h2 {
      font-size: 1.7em;
    }
  }
}
</style>
<script>
export default {
  name: 'Footer',
};
</script>
