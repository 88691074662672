<template>
  <div>
    <!-- <section class="section-5">
      <h2>Print</h2>
      <div class="bottom">
        <div>
          <a href="https://www.print.heyjura.de/">
            <button @>MEHR INFOS</button>
          </a>
        </div>
      </div>
    </section> -->
    <section class="section-2">
      <h2>So einfach</h2>
      <div class="cards">
        <div>
          <span class="counter">1</span>
          <FlatIcon icon="adjustment" />
          <h3>Suchen und Finden</h3>
          <p>
            Suche Dir aus, was Du lernen möchtest.
            <b>Fälle, Definitionen und Schemata</b> aus Zivil-, Straf- und Öffentlichem Recht vom
            <b>1. Semester</b> bis zum <b>1. Staatsexamen.</b>
          </p>
        </div>
        <div>
          <span class="counter">2</span>
          <FlatIcon icon="basket" />
          <h3>Individualität</h3>
          <p>
            Lege Fälle, Schemata und Definitionen in den Warenkorb und
            <b>individualisiere</b> Dein Inhaltsverzeichnis mittels Drag&Drop.
          </p>
        </div>
        <div>
          <span class="counter">3</span>
          <FlatIcon icon="download" />
          <h3>Download</h3>
          <p>
            Lade Dir Dein <b>eigenes Skript</b> als einheitliche PDF herunter und lerne wo und wann Du möchtest.
          </p>
        </div>
      </div>
    </section>

    <section class="section-3">
      <div class="main">
        <div v-if="!isMobile()">
          <PhoneVideo />
        </div>
        <div>
          <h2>So schnell</h2>
          <p>Schneller als Google.</p>
          <p>Schneller als in der Bib.</p>
          <p>Schneller als jedes Lehrbuch.</p>
        </div>
      </div>
      <div class="bottom">
        <div>
          <button @click="scrollTop()">LOS GEHT'S</button>
        </div>
        <div>
          <!--<p>Kostenlos</p>-->
        </div>
      </div>
    </section>

    <!--
    <section class="section-3a">
      <div class="row">
        <h2>Mit Fällen lernen</h2>
        <div class="column_left">
          <p>
            <b>Fälle</b> eignen sich super, um Lernstoff und Streitstände zu vertiefen oder sich auf
            Klausuren vorzubereiten. Die von uns angebotenen Fälle werden von dem
            <b>Fall Fallag</b> bereitgestellt und enthalten jeweils eine <b>ausführliche Musterlösung</b> im Gutachtenstil. Darüber hinaus helfen Dir fallspezifische <b>Quizfragen</b> Dich
            abzufragen und etwaige Lernlücken schnell zu schließen.
          </p>
          <a href="https://heyjura.de/casesFullSearch" class="calltoaction_cases">👉 Nach Fällen suchen</a>
        </div>
        <div class="column_right">
          <img src="../assets/img/ipad.png" alt="IPad_Fälle" class="responsive-img" />
        </div>
      </div>
    </section>
    -->

    <section class="section-3a heyJuraAI">
      <div class="row">
        <div class="avasphere_column">
          <div class="avasphere_video">
            <video src="../assets/video/avasphere.mp4" autoplay loop="loop" muted defaultMuted playsinline
              oncontextmenu="return false;" preload="auto" width="400"></video>
          </div>
          <a href="https://chat.heyjura.de" class="fakeloader_event">
            <div class="calltoaction_chatbot_button">
              <button>
                <!--<div class="bounce">👉</div>-->
                <div class="icon bounce_arrow">
                  <div class="arrow"></div>
                </div>
                Chat starten
              </button>
            </div>
          </a>
          <h2 class="title_KI">Der <span>KI-Tutor</span> von heyJura</h2>
          <div class="ava_text">
            <p>
              Unser <b>KI-Tutor Ava</b> hilft dir <b>Jura endlich zu verstehen</b> anstatt weiter
              auswendig lernen zu müssen!
            </p>
            <p>Wir haben einen KI-Tutor auf der Grundlage von GPT-4
              entwickelt, der weit mehr kann, als nur Definitionen abfragen. Er bietet Dir eine ganz neue
              Lernerfahrung, hilft Dir und unterstützt Dich, wenn Du mal nicht weiter weißt. Ava
              fokussiert
              sich darauf, Dir Jura in einem
              <!--<b>interaktiven Austausch</b> beizubringen. Unser KI-Tutor ist <b>kostenlos</b>, da er sich
              noch in der Beta-Phase befindet.-->
            </p>
            <a href="https://chat.heyjura.de" class="calltoaction_ava fakeloader_event">🔒
              Zur Zeit im Umbau</a>
          </div>
        </div>
      </div>
    </section>

    <section class="section-3a PDF_showcase">
      <div class="row">
        <h2>Modulare PDF erstellen</h2>
        <div class="column_right scematic">
          <img src="../assets/img/schemata.png" alt="Übersicht_Schema" class="responsive-img">
        </div>
        <div class="column_left scematic_text">
          <p>Alle gewünschten Lernunterlagen werden übersichtlich als <b>einheitliche PDF</b> zusammengefügt. Unsere
            Schemata bestehen aus einem übersichtlichen Kurzschema und dem dazugehörigen detaillierten Langschema,
            zahlreichen <b>Beispielen, Streiständen und interaktiven Quellen</b>. Definitionssammlungen enthalten
            <b>alle relevanten Definitionen</b> auf einem Blick, sodass sie sich einfach lernen lassen.</p>
          <a href="https://heyjura.de/fullSearch" class="calltoaction_cases">Zur schematischen Suche 👈</a>
        </div>
      </div>
    </section>

    <section class="section-numbers">
      <div class="row-numbers">
        <div>
          <h3>13.000+</h3> <!-- Last change 18.03.2024 -->
          <p>
            bereits heruntergeladene Lerneinheiten aus Schemata, Definitionen und Fällen. Entspricht in etwa 65.000
            A4-Seiten.
          </p>
        </div>
        <div>
          <h3>1000+</h3>
          <p>
            Definitionen lassen sich einfach und schnell in unserer Suchmaschine suchen und finden.
          </p>
        </div>
        <div>
          <h3>220+</h3>
          <p>
            Schemata aus Zivil-, Straf-, und Öffentlichem Recht kannst Du Dir herunterladen. Die Klausuren können also
            kommen.
          </p>
        </div>
      </div>
    </section>

    <section class="section-6">
      <h2 class="title-pricing">Preise</h2>
      <div class="container">
        <div class="pricing-text">
          <p>
            Mit einem S-, M- oder L-<b>Lern-Paket</b>, kannst Du Dir ein eigenes Skript modular zusammenstellen, mit all
            den Lerneinheiten, die Du gerade lernen möchtest. <b>Eine Lerneinheit</b> entspricht dabei einem Fall, einem
            Schema oder einer Definitionssammlung, die Du nach Belieben kombinieren und als PDF downloaden kannst.
          </p>
          <!-- <p>👉 <b>Lade einen Kommilitonen ein</b> und erhalte zusätzlich zu Deinem Kauf eines M- oder L-Pakets ein M-Paket <b>kostenlos</b> dazu.
          </p> -->
        </div>
        <PricingTable @select="handleBundleSelected" />
      </div>
    </section>


    <section class="section-4">
      <h2 class="title-demo">Demo-Skript</h2>
      <div class="text_demo">
        <p>Downloade Dir jetzt <b>kostenlos</b> ein Demo-Skript und überzeuge Dich selbst von der <b>Qualität, der
            Übersichtlichkeit und dem Aufbau</b> unserer Lernunterlagen.
        </p>
      </div>
      <div class="main">
        <a href="/heyJura_Demo-Skript.pdf" download="heyJura_Demo-Skript.pdf">
          <img class="demo-logo" src="../assets/img/Demo-Skript-Icon_centered_02.png" />
        </a>
      </div>
    </section>

    <section class="section-newsletter">
      <div class="row">
        <h2>Der Prädikatsnewsletter 📬</h2>
        <div class="text_newsletter">
          <p>Registriere Dich für unseren Newsletter und wir versorgen Dich mit kostenlosen Tipps, mit denen Du zum
            Prädikatskandidaten wirst.</p>
          <div class="newsletter_form">
            <iframe src="https://embeds.beehiiv.com/839e6612-0ef7-41f0-aa06-b825e015444d?slim=true"
              data-test-id="beehiiv-embed" height="52" frameborder="0" scrolling="no"
              style="margin: 0; border-radius: 0px !important; background-color: transparent;"></iframe>
          </div>
          <p class="newsletter_privacy">Mit Deiner Anmeldung akzeptierst Du unsere <a href="https://heyjura.de/privacy"
              target="_blank">Datenschutzerklärung</a> und stimmst zu Mails von heyJura zu bekommen.</p>
        </div>
      </div>
    </section>

    <!-- Section reviews -->
    <section class="section-review">
      <h2>Reviews 🦄</h2>
      <div class="review_subtitle">
        <p>Ehrliches Feedback von ehrlichen Menschen. Überzeuge Dich selbst.</p>
      </div>
      <div class="row-review">
        <div class="review_content">
          <img class="review_image" src="../assets/img/antonia.png" alt="antonia_review" />
          <p class="short_review">Richtig, richtig gut!</p>
          <p>
            Super easy und verständlich für jeden! Finde ich so wichtig, dass jeder abgeholt wird, egal auf welchem
            Wissensstand die Person ist. Das ist super. Danke dafür!
          </p>
          <a target="__blank" href="https://www.instagram.com/toniii.kie/">
            <img class="instagram_icon" src="../assets/img/instagram.png" alt="Instagram" />
            Antonia - Universität Köln
          </a>
          <p>⭐️⭐️⭐️⭐️⭐️</p>
        </div>
        <div class="review_content">
          <img class="review_image" src="../assets/img/narvik.png" alt="narvik_review" />
          <p class="short_review">Wunderbares Lernkonzept.</p>
          <p>
            Das Konzept von heyJura hat mich überzeugt. Sehr gute Ergänzung für nahezu jedes Lernkonzept! Das Lernen
            wird eindeutig erleichtert; egal ob man sich zunächst einen Überblick verschaffen möchte oder ob man
            vertieft einsteigt.
          </p>
          <a target="__blank" href="https://www.instagram.com/nrvk.r/">
            <img class="instagram_icon" src="../assets/img/instagram.png" alt="Instagram" />
            Narvik - Universität Bonn
          </a>
          <p>⭐️⭐️⭐️⭐️⭐️</p>
        </div>
        <div class="review_content">
          <img class="review_image" src="../assets/img/chrissi.png" alt="Chrissi_review" />
          <p class="short_review">Sehr gelungen. Bin begeistert.</p>
          <p>
            Ich nutze heyJura mit großer Begeisterung, weil ich die Seite sehr einfach und verständlich aufgebaut finde.
            Hier findet sich jeder zu Recht und gelangt ohne Schnörkel zu dem, was auch wirklich relevant für die
            Prüfungsvorbereitung ist.
          </p>
          <a target="__blank" href="https://www.instagram.com/chrissi.kiemchen/">
            <img class="instagram_icon" src="../assets/img/instagram.png" alt="Instagram" />
            Christopher - Universität Bielefeld
          </a>
          <p>⭐️⭐️⭐️⭐️⭐️</p>
        </div>
      </div>
    </section>

    <!-- Section 8 FAQ html -->
    <section class="faq-section">
      <h2 class="title-demo">Noch Fragen?</h2>
      <div class="faq-row">
        <p class="faq_subtitles">Was Du sicherlich unbedingt schonmal über heyJura wissen wolltest.</p>
        <div class="faq-container">
          <div class="faq-item">
            <span class="numbers_faq">1</span>
            <h2>Lehrbuch oder heyJura?</h2>
            <p>Lehrbücher sind umfangreich, sehr umfangreich. Meistens kauft man sie, liest 20, 30 Seiten und legt sie
              dann zur Seite. Wir finden, dass Lehrbücher zu sehr in die Tiefe gehen und sich gerne in der Fülle
              verlieren. Außerdem behandeln sie meist nur ein Rechtsgebiet. Bei heyJura kannst Du Dir eine PDF aus
              insgesamt 18 Rechtsgebieten zusammenstellen, was nicht nur effizienter, sondern preislich unschlagbar ist.
            </p>
          </div>
          <div class="faq-item">
            <span class="numbers_faq">2</span>
            <h2>Wer schreibt den Legal Content von heyJura?</h2>
            <p>Unser Legal Content wird von herausragenden Studierenden verfasst, die enthusiastische Expert:innen in
              denen ihnen zugeteilten Rechtsgebieten sind und überdurchschnittliche universitäre Leistungen erbringen
              oder bereits ein Prädikatsexamen haben.</p>
          </div>
          <div class="faq-item">
            <span class="numbers_faq">3</span>
            <h2>Sind die Schemata vollständig?</h2>
            <p>Wir erweitern unsere Lerninhalte stetig und decken heute umfassenden Lernstoff bis zum 1. Staatsexamen
              ab. Schemata, die wir für besonders wichtig erachten, wurden sehr umfassend und tiefgehend verfasst, mit
              zahlreichen interaktiven Quellen hinterlegt und mit Beispielen untermauert, um das Verständnis zu
              vereinfachen.</p>
          </div>
          <div class="faq-item">
            <span class="numbers_faq">4</span>
            <h2>Auf welchem Niveau sind die Unterlagen?</h2>
            <p>Wir verfolgen ein hohes wissenschaftliches Niveau, zitieren ordnungsgemäß und verstärken Ausführungen mit
              Beispielen und Argumenten. Unser Anspruch ist es, dass wirklich jeder Studierende unseren Lerncontent
              direkt beim ersten Durchlesen versteht und sich gerade nicht durch lange Schachtelsätze und unnötig
              verkomplizierte Ausführungen kämpfen muss. Denn dann macht Jura wirklich Spaß!</p>
          </div>
          <div class="faq-item">
            <span class="numbers_faq">5</span>
            <h2>Was ist 1 Lerneinheit?</h2>
            <p>Unter einer Lerneinheit verstehen wir ein Schema, ein Fall oder eine Definitionssammlung. Letzteres
              umfasst alle Definitionen einer bestimmten Rechtsnorm und bietet somit einen perfekten Blick auf
              inhaltliche Zusammenhänge. Möchtest du beispielsweise ein Schema zu § 985 BGB, eine Definitionssammlung zu
              §§ 812 ff. BGB und einen Sachenrechts-Fall herunterladen, sind dies insgesamt 3 Lerneinheiten, die in 1
              PDF zusammengeführt werden.</p>
          </div>
          <div class="faq-item">
            <span class="numbers_faq">6</span>
            <h2>Was ist die Grundidee von heyJura?</h2>
            <p>Wir möchten Studierenden eine Alternative zu teuren Lehrbüchern und ineffizienten Lernmethoden aufzeigen.
              Indem Studierende selbst entscheiden können, was sie lernen möchten und was sie brauchen, sind sie
              unabhängiger und sparen jede Menge Zeit bei dem Zusammensuchen von hochqualifizierten Lernunterlagen, die
              dann gebunden als einheitliche PDF heruntergeladen werden können.</p>
          </div>
          <!-- More FAQ items as needed -->
        </div>
        <div class="bottom">
          <div class="centered_bottom">
            <button @click="scrollTop()">ZUR SUCHE</button>
          </div>
          <div>
            <!--<p>Kostenlos</p>-->
          </div>
        </div>
      </div>
    </section>
    <!-- Section 8 FAQ html -->

    <!-- AI fixed Icon beginning -->    
    <a href="https://chat.heyjura.de" class="chatbot-button tooltip-container" target="_blank">
      <img src="../assets/img/avasphere_chatbot.gif" alt="chatbot image"/>
      <div class="tooltip">KI-Tutor</div>
      <div class="pulsating-circle"></div>
    </a>
    <!-- AI fixed Icon ending -->

  </div>
</template>

<script>
import FlatIcon from '../components/FlatIcon.vue';
import PricingTable from './PricingTable.vue';

const PhoneVideo = () => import('../components/PhoneVideo.vue');

export default {
  name: 'HomeContent',
  components: {
    FlatIcon,
    PhoneVideo,
    PricingTable,
  },
  methods: {
    scrollTop() {
      scrollTo({ top: 0, behavior: 'smooth' });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        return true;
      } else {
        return false;
      }
    },
    handleBundleSelected(bundle) {
      this.$router.push({ name: 'Bundles', params: { bundle: bundle } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/css/_variables';

h2 {
  margin: 100px 0px;
  font-size: 3em;
  text-align: center;
  color: $darkBlue;

  @media screen and (max-width: 640px) {
    font-size: 1.75em;
  }
}

.blue {
  color: $blue;
  display: block;
}

.section-2 {
  background: white;
  padding: 20px 0px;

  .cards {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: $darkBlue;

    @media screen and (max-width: 640px) {
      padding: 0em 1em;
    }

    div {
      position: relative;
      max-width: 22em;
      text-align: center;
      margin-bottom: 4em;

      h3 {
        color: $blue;
        font-size: 25px;

        @media screen and (max-width: 640px) {
          font-size: 1.25em;
        }
      }

      span {
        position: absolute;
        left: 25px;
        top: 10px;
      }

      @media screen and (max-width: 640px) {
        p {
          font-size: 16px;
        }

        .title-demo {
          margin: 0 !important;
        }
      }
    }
  }
}

.section-3 {
  .main {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    margin-bottom: 100px;

    h2 {
      text-align: left;
      margin-bottom: 40px;

      @media screen and (max-width: 640px) {
        font-size: 1.7em;
        margin: 25px;
      }
    }

    p {
      margin: 0px;
      color: $darkBlue;
      font-size: 21px;

      @media screen and (max-width: 640px) {
        font-size: 1.25em;
      }
    }

    @media screen and (max-width: 640px) {
      display: none;
    }
  }

  .bottom {
    margin-bottom: 100px;
    padding-top: 1em;

    div {
      display: flex;
      justify-content: center;
    }

    @media screen and (max-width: 640px) {
      margin: 60px;
    }
  }

  button {
    box-shadow: 0px 20px 40px rgba(0, 40, 60, 0.30);

    @media screen and (max-width: 640px) {
      padding: 15px 55px;
    }
  }
}

/*Section 3a Fälle css*/
.section-3a {
  background-color: #fff;
}

.section-3a .row {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.row::after {
  content: '';
  clear: both;
  display: table;
}

.section-3a .row h2 {
  @media screen and (max-width: 640px) {
    margin: 100px 0px 20px 0px;
    padding: 0em 1em;
  }
}

/*KI Tutor*/
.section-3a .row .title_KI {
  margin: 100px 0px 20px 0px;
}

.section-3a .row .title_KI span,
.section-scematicSearch .row span {
  background: linear-gradient(to right, #45b1fb, #6580e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heyJuraAI .row .avasphere_video {
  position: relative;
  min-width: 340px;

  video {
    height: 100%;
    max-width: 45%;
    margin: 0px auto;
    display: block;
    position: relative;
    clip-path: inset(1px 1px);

    @media screen and (max-width: 640px) {
      max-width: 60%;
    }
  }
}

.avasphere_column {
  max-width: 800px;
  margin: 0px auto;
}

.avasphere_column a {
  text-decoration: none;
}

/* Das keyframe sorgt dafür, dass der Button floated */
@keyframes floatMore-jsx-2963547441 {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(5px);
  }
}

.calltoaction_chatbot_button {
  display: flex;
  width: 272px;
  margin: 0px auto;

  @media screen and (max-width: 640px) {
    width: 255px;
    padding: 15px 30px !important;
  }
}

.calltoaction_chatbot_button button {
  display: flex;
  align-items: center;
  background: rgba(2, 136, 209, 1.00) !important;
  background: -moz-linear-gradient(200deg, rgb(102 125 228) 10%, rgb(62 187 255) 100%) !important;
  background: -webkit-linear-gradient(200deg, rgb(102 125 228) 10%, rgb(62 187 255) 100%) !important;
  background: linear-gradient(200deg, rgb(102 125 228) 10%, rgb(62 187 255) 100%) !important;
  box-shadow: 0px 20px 40px rgba(0, 40, 60, 0.30) !important;
  transition: 0.3s !important;
  padding: 20px 40px;
  width: 272px;

  &:hover {
    text-align: center;
    background: rgba(2, 136, 209, 1.00) !important;
    background: -moz-linear-gradient(200deg, rgb(146 142 230) 10%, rgb(62 187 255) 100%) !important;
    background: -webkit-linear-gradient(200deg, rgb(146 142 230) 10%, rgb(62 187 255) 100%) !important;
    background: linear-gradient(200deg, rgb(146 142 230) 10%, rgb(62 187 255) 100%) !important;
  }

  &:active {
    transform: translateY(5px);
  }
}

.calltoaction_chatbot_button button .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 30px;
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
  }

  20% {
    transform: translateX(1px);
  }

  40% {
    transform: translateX(3px);
  }

  60% {
    transform: translateX(5px);
  }

  80% {
    transform: translateX(3px);
  }

  100% {
    transform: translateX(0px);
  }
}

.calltoaction_chatbot_button button .arrow {
  width: 20px;
  height: 3px;
  background-color: #fff;
  animation: arrow 700ms linear infinite;
  border-radius: 6px;
  position: relative;
}

.calltoaction_chatbot_button button .arrow::after,
.arrow::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 3px;
  right: -1px;
  background-color: #fff;
  border-radius: 3px;
}

.calltoaction_chatbot_button button .arrow::after {
  top: -3px;
  transform: rotate(45deg);
}

.calltoaction_chatbot_button button .arrow::before {
  top: 3px;
  transform: rotate(-45deg);
}




.calltoaction_chatbot_button button p {
  text-decoration: none;
  color: #fff;
  padding: 0px 65px;

  @media screen and (max-width: 640px) {
    padding: 3px 65px;
  }
}

.calltoaction_chatbot_button button .bounce_arrow {
  animation: bounce 1s linear infinite;
  padding-right: 5px;
}

.column_left {
  float: left;
  width: calc(40% - 30px);
  /* subtract double the padding from the width */
  padding: 15px;
}

.column_left p {
  color: #002060;
  font-size: 21px;

  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
}

.column_left .calltoaction_cases {
  color: #4285f4;
  font-size: 21px;
  text-decoration: none;
  transition: transform 0.3s;

  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
}

.column_left .calltoaction_cases:hover {
  color: #002060;
}

.scematic_text {
  padding: 15px 15px 15px 40px;

  @media screen and (max-width: 1000px) {
    padding: 15px;
  }
}

.scematic {
  padding: 0px;

  @media screen and (max-width: 1000px) {
    margin-bottom: 30px;
    padding: 0px;
  }

}

.column_right {
  float: left;
  width: calc(60% - 30px);
  /* subtract double the padding from the width */
  box-shadow: 0 10px 70px rgba(0, 0, 0, 0.06);
  margin-bottom: 100px;

  @media screen and (max-width: 1000px) {
    margin-top: 40px;
  }
}

.responsive-img {
  width: 100%;
  height: auto;
}

.PDF_showcase {
  background-color: #f5f7ff;
}


.section-scematicSearch .row h2,
.section-newsletter {
  padding: 20px;
}

.section-newsletter .row h2 {
  margin: 100px 0 20px 0;
  padding: 0em 1em;
}

.section-newsletter .row .text_newsletter {
  max-width: 800px;
  margin: 0 auto;
}

.section-newsletter .row .text_newsletter p,
.ava_text p {
  font-size: 21px;
  padding: 0em 2em;

  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
}

.section-newsletter .row .text_newsletter p,
.ava_text p {
  color: #002060;
}

.ava_text a {
  padding: 0 2em;
  font-size: 21px;
  text-decoration: none;
}


.section-newsletter .row .text_newsletter .newsletter_privacy {
  font-size: 13px;
  margin: 30px 0px 100px 0px;
  text-align: center;
}

.section-newsletter .row .text_newsletter .newsletter_privacy a {
  color: #002060;
  text-decoration: none;
}

.section-newsletter .row .text_newsletter .newsletter_form {
  padding: 0em 2em;
  margin: 0px auto;
}

.section-newsletter .row .text_newsletter .newsletter_form {
  width: 300px;
  margin-top: 30px;
}

.section-scematicSearch .row .text_scematicSearch .calltoaction_scematicSearch,
.calltoaction_ava {
    color: #4285f4;
    text-decoration: none;
    transition: transform .3s;
}

/* Responsive layout - makes the menu and the content stack on top of each other */
@media screen and (max-width: 1000px) {

  .column_left,
  .column_right {
    width: 100%;
    /* padding: 15px; */
  }
}

.section-numbers {
  padding-top: 139px;
  background: #fff;
}

.section-numbers .row-numbers {
  max-width: 90%;
  margin: 0px auto;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.section-numbers .row-numbers div {
  position: relative;
  max-width: 22em;
  text-align: center;
  margin-bottom: 4em;
}

.section-numbers .row-numbers div h3 {
  color: #002060;
  font-size: 40px;

  @media screen and (max-width: 640px) {
    font-size: 1.25em;
  }
}

.section-numbers .row-numbers div p {
  color: #002060;
  font-size: 21px;

  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
}

/*Section Zahlen Daten Fakten css*/


/*Section reviews start*/
/*Section Zahlen Daten Fakten css*/
.section-review {
  padding: 20px;
}

.section-review h2 {
  margin: 100px 0 20px 0;
}

.section-numbers,
.section-review {
  background: #fff;
}

.section-review .review_subtitle {
  max-width: 800px;
  margin: 0 auto;
}

.section-review .review_subtitle p {
  color: #002060;
  font-size: 21px;
  text-align: center;
  margin-bottom: 40px;
  padding: 0 2em;

  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
}

.section-numbers .row-numbers,
.section-review .row-review {
  max-width: 90%;
  margin: 0px auto;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0em 2em;
}

.section-numbers .row-numbers div,
.section-review .row-review .review_content {
  position: relative;
  max-width: 22em;
  margin-bottom: 4em;

  @media screen and (max-width: 640px) {
    text-align: center;
  }
}

.section-numbers .row-numbers div h3 {
  color: #002060;
  font-size: 40px;

  @media screen and (max-width: 640px) {
    font-size: 1.25em;
  }
}

.section-numbers .row-numbers div p,
.section-review .row-review .review_content p {
  color: #002060;
  font-size: 21px;

  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
}

.section-review .row-review .review_content .short_review {
  font-size: 19px;
  font-weight: 700;

  @media screen and (max-width: 640px) {
    font-size: 15px;
  }
}

.section-review .row-review .review_content img {
  height: 60px;
  width: 60px;
  border-radius: 100%;

  @media screen and (max-width: 640px) {
    height: 40px;
    width: 40px;
  }
}

.section-review .row-review .review_content a {
  color: #002060;
  text-decoration: none;
  font-size: 21px;
  font-weight: bold;

  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
}

.section-review .row-review .review_content a img {
  height: 17px;
  width: 17px;
  border-radius: 0px;
  margin-right: 10px;
}

/*Section reviews stop*/

/*end section 3a*/

.section-4 {
  background: white;
  padding: 20px 0px;

  .main {
    display: flex;
    justify-content: center;
    margin-bottom: 55px;
  }
}

.section-4 h2 {
  margin: 100px 0px 20px 0px;
}

.section-4 .text_demo {
  max-width: 800px;
  margin: 0 auto;
}

.section-4 .text_demo p {
  color: #002060;
  font-size: 21px;
  padding: 0 2em;

  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
}

.demo-logo {
  width: 180px;

  @media screen and (max-width: 640px) {
    width: 120px;
  }
}

.section-5 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  background: url(../assets/img/print_page.jpg) no-repeat center center / cover;
  box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.3);
  padding: 20px 0px;
  // display: flex;

  h2 {
    margin: 0px 0px 0;
    font-size: 8em;
    text-align: center;
    color: $darkBlue;

    @media screen and (max-width: 640px) {
      font-size: 4em;
    }
  }

  p {
    max-width: 600px;
    text-align: center;
    padding: 1em 4em 1em;
    margin: 20px 0;
    color: $darkBlue;
    font-size: 21px;

    @media screen and (max-width: 640px) {
      font-size: 1.25em;
    }
  }

  button {
    background-color: #a4dda2;
    text-align: center;
    color: #fff;
    padding: 18px 65px;
    border: none;
    border-radius: 33px;
    font-size: 20px;
    cursor: pointer;
    transition: transform 0.3s;
    // margin-bottom: 50px;

    @media screen and (max-width: 640px) {
      padding: 14px 45px;
      font-size: 18px;
    }
  }

  button:hover {
    background-color: #ade9ab;
  }

  @media screen and (max-width: 640px) {
    min-height: 400px;

    h2 {
      font-size: 4em;
    }
  }
}

.section-6 {

  /* section 6 Pricing table CSS */
  .title-pricing {
    margin: 100px 0px 20px 0px;
  }

  .container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }

  .pricing-text {
    max-width: 50%;
    margin: auto;

    @media screen and (max-width: 640px) {
      max-width: 90%;
    }
  }

  .pricing-text p {
    color: #002060;
    font-size: 21px;
    text-align: left;
    margin: 0;

    @media screen and (max-width: 640px) {
      font-size: 16px;
    }
  }

  .pricing-text p:nth-child(2) {
    margin-top: 20px;
  }
}


/*Section 8 FAQ css*/
.faq-section h2 {
  margin: 100px 0 20px 0;
}

.faq-row {
  max-width: 1200px;
  margin: 0 auto;
}

.faq-row .faq_subtitles {
  color: #002060;
  font-size: 21px;
  text-align: center;
  margin-bottom: 3em;
  padding: 20px;

  @media (max-width: 640px) {
    font-size: 16px;
  }
}

.faq-row p {
  color: #002060;
  font-size: 21px;
  text-align: center;
  margin-bottom: 3em;

  @media (max-width: 640px) {
    font-size: 16px;
    margin-bottom: 1em;
  }
}

.faq-row .bottom .centered_bottom {
  display: flex;
  justify-content: center;
  padding: 100px;

  @media (max-width: 640px) {
    padding: 20px 20px 100px 20px;
  }
}

.faq-row .bottom .centered_bottom button {
  font-size: 20px;
  box-shadow: 0px 20px 40px rgba(0, 40, 60, 0.30);

  @media (max-width: 640px) {
    font-size: 1em;
    padding: 15px 55px;
  }
}

.faq-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* default to a two-column layout */
  gap: 20px;
  /* space between grid items */
  padding: 20px;
}

.faq-item {
  padding: 20px;
}

.faq-item h2 {
  margin: 0;
  color: #002060;
  font-size: 21px;
  text-align: left;

  @media (max-width: 640px) {
    font-size: 16px;
  }
}

.faq-item p {
  text-align: left;
  font-size: 18px;

  @media (max-width: 640px) {
    font-size: 16px;
  }
}

.faq-item .numbers_faq {
  background: #4285f4;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 10px;

  @media screen and (max-width: 640px) {
    font-size: 16px;
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 640px) {
  .faq-container {
    grid-template-columns: 1fr;
    /* single-column layout */
  }
}

/*Fixed AI Chat Icon beginning */
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
  
.tooltip {
  visibility: hidden;
  width: 80px;
  font-weight: bold;
  background-color: #fff;
  color: #002060;
  text-align: center;
  border-radius: 30px;
  padding: 6px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 45%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  box-shadow: 0 10px 70px rgba(0,0,0,.26);
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
  
  .chatbot-button {
    z-index: 999999;
    position: fixed;  
    right: 25px; 
    bottom: 25px;
  }
  
  .pulsating-circle {
  position: absolute;
  left: 80%;
  top: 90%;
  transform: translateX(-50%) translateY(-50%);
  width: 15px;
  height: 15px;
  border: 3px solid #fff;
  border-radius: 30px;
  
  &:before {
    content: '';
    position: relative;
    display: block;
    width: 200%;
    height: 200%;
    box-sizing: border-box;
    margin-left: -50%;
    margin-top: -50%;
    border-radius: 45px;
    background-color: #2ABE77;
    animation: pulse-ring 5.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  
  &:after {
    content: '';
    position: absolute;
    left: 0; 
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #2ABE77;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0,0,0,.3);
    animation: pulse-dot 5.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}

.chatbot-button img {
  width: 70px;
  height: 70px;
  }

/*Fixed AI Chat Icon ending */
</style>
