<template>
  <div>
    <section class="section-1">
      <a href="https://payhip.com/heyJura" class="container-link">
        <div class="container">
            NEU: 😍 Unsere Kompakt-Skripte zu Zivil- und Strafrecht sind da! Hier klicken.
        </div>
    </a>
      <h1>
        <span class="blue">Dein eigenes Skript als PDF.</span>Aus Schemata und
        Definitionen.
      </h1>
      <div>
        <Search />
      </div>
    </section>

    <HomeContent />
  </div>



</template>

<style lang="scss" scoped>
@import "../assets/css/_variables";

h1,
h2 {
  margin: 60px 0px;
  font-size: 3.0em;
  text-align: center;
  color: $darkBlue;

  @media screen and (max-width: 640px) {
    font-size: 1.7em;
  }
}

.blue {
  color: $blue;
  display: block;

  @media screen and (max-width: 640px) {
    padding: 0.1em;
  }
}

.section-1 {
  div {
    margin-bottom: 100px;
  }
}

.container-link {
    text-decoration: none;
    display: flex;
    justify-content: center; /* Zentriert den div horizontal */
}

.container {
    width: 400px;                 /* Breite des Containers */
    padding: 20px;                /* Innenabstand */
    color: #fff;                  /* Textfarbe */
    text-align: center;           /* Zentriert den Text im div */
    margin-top: 20px;             /* Abstand vom oberen Rand */
    margin-bottom:50px;
    border-radius: 8px;           /* Abgerundete Ecken */
    cursor: pointer;              /* Zeigt den Zeiger an */
    background: rgba(2, 136, 209, 1.00) !important;
    background: -moz-linear-gradient(200deg, rgb(102 125 228) 10%, rgb(62 187 255) 100%) !important;
    background: -webkit-linear-gradient(200deg, rgb(102 125 228) 10%, rgb(62 187 255) 100%) !important;
    background: linear-gradient(200deg, rgb(102 125 228) 10%, rgb(62 187 255) 100%) !important;
    box-shadow: 0px 20px 40px rgba(0, 40, 60, 0.30) !important;
    transition: 0.3s !important;

    @media screen and (max-width: 640px) {
    width: 250px;
  }

    &:hover {
        text-align: center;
        background: rgba(2, 136, 209, 1.00) !important;
        background: -moz-linear-gradient(200deg, rgb(146 142 230) 10%, rgb(62 187 255) 100%) !important;
        background: -webkit-linear-gradient(200deg, rgb(146 142 230) 10%, rgb(62 187 255) 100%) !important;
        background: linear-gradient(200deg, rgb(146 142 230) 10%, rgb(62 187 255) 100%) !important;
    }
}

</style>

<script>
import Search from "../components/Search.vue";
import HomeContent from "../components/HomeContent.vue";

export default {
  name: "Home",
  components: {
    Search,
    HomeContent
  },
};
</script>
