<template>
  <section class="section-1" v-if="item">
    <div class="item">
      <div class="flex">
        <div>
          <h3>{{ item.title }}</h3>
          <h4>Definition</h4>
          <div class="definition_description" v-html="item.description"></div>
        </div>
      </div>
    </div>
    
    <div class="item">
      <h2>Enthalten in:</h2>
    </div>
    <div
      class="parents"
      v-if="item.topics && Object.keys(item.topics).length !== 0"
    >
      <div class="parent">
        <div v-for="topic in item.topics" :key="topic.id">
          <div class="parent_title">
            <AddToBasketBtn class="basketBtn" :item="addtype(topic, 'topic')" />
            <h2>{{ topic.title }}</h2>
            <h4>Definitionen</h4>
          </div>
          <div class="parent_children">
            <div
              class="parent_children_item"
              v-for="definition in topic.definitions"
              :key="definition.id"
            >
              <h3>{{ definition.title }}</h3>
              <div class="definition_description" v-html="definition.description">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="parents"
      v-if="item.schemata && Object.keys(item.schemata).length !== 0"
    >
      <div class="parent">
        <div v-for="schema in item.schemata" :key="schema.id">
          <div class="parent_title">
            <AddToBasketBtn
              class="basketBtn"
              :item="addtype(schema, 'schema')"
            />
            <h2>{{ schema.title }}</h2>
          </div>
          <!-- <div
              class="parent_children_item"
              v-for="definition in schema.definitions"
              :key="definition.id"
            >
              <h3>{{ definition.title }}</h3>
              <div v-html="definition.description"></div>
            </div> -->
        </div>
      </div>
    </div>

    
  </section>
</template>

<script>
// import axios from 'axios';
import AddToBasketBtn from '../components/AddToBasketBtn.vue';

export default {
  name: 'Definition',
  components: {
    AddToBasketBtn,
  },
  data() {
    return {
      item: null,
      loader: null,
    };
  },

  methods: {
    addtype(item, type) {
      item.type = type;
      return item;
    },
    parseCustomTags(line, formatTag, htmlTag) {
      const parts = line.split(formatTag);
      if (parts.length > 1) {
        line = parts[0];
        for (let i = 1; i < parts.length; i++) {
          line += (i % 2 == 1 ? `<${htmlTag}>` : `</${htmlTag}>`) + parts[i];
        }
        if (parts.length % 2 == 0) {
          line += `</${htmlTag}>`;
        }
      }
      return line;
    },
  },
  async mounted() {
    this.loader = this.$loading.show({});
    // this.item = (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/getPDF?id=${this.$route.params.id}`)).data;
    this.item = await this.$api
      .get(`/definitions`, {
        params: {
          filters: {
            slug: {
              $eq: this.$route.params.id,
            },
          },
          populate: {
            topics: { populate: ['definitions'] },
            schemata: { populate: ['definitions'] },
          },
        },
      })
      .then((response) => {
        const transformedData = this.$findOne.transform(response.data);
        console.log(transformedData);
        transformedData.type = 'definition';
        return transformedData;
      });

    this.loader.hide();
  },
};
</script>
<style lang="scss" scoped>
/* Vue Css Loader Magic (to access the v-html content) */




</style>

<style lang="scss" scoped>
@import '../assets/css/_variables';
.section-1 {
  padding: 4em 8em;
  border-radius: 7px;
  margin: 0px auto;
  max-width: 800px;
  
  @media screen and (max-width: 992px) {
    padding: 4em 4em;
  }
  
  @media screen and (max-width: 640px) {
    padding: 1em;
  }
}

section {
  padding: 1em;
  color: $darkBlue;
  letter-spacing: 0.007em;
  line-height: 25px;

  div {
    position: relative;
  }
}

.item {
  position: relative;
  max-width: 730px;
  flex-basis: auto;
  flex-grow: 1;
  width: 100%;
  color: $darkBlue;
  margin-left: 35px;
  margin-bottom: 4em;

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  
    @media screen and (max-width: 640px) {
      margin: 0px 15px 35px 15px;
  }

  h3 {
    font-size: 2.5em;
    margin-bottom: 10px;
    user-select: none;
    line-height: 1.5;
    overflow-wrap: anywhere;
  
      @media screen and (max-width: 640px) {
      font-size: 1.75em;
    }
  }

  h4 {
    margin: 0px;
    opacity: 0.4;
  }
  h2 {
    color: $blue;
    position: relative;
    max-width: 800px;
    flex-basis: auto;
    flex-grow: 1;
    width: 100%;

    margin-bottom: 1em;
  }

  p {
    margin: 0px;
    width: 90%;
  }

  span {
    position: absolute;
    left: -35px;
    top: 3px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.parents {
  background: #fff;
  padding: 4em;
  box-shadow: 0 10px 70px rgba(0,0,0,.04);
  border-radius: 7px;
  margin-bottom: 60px;

  @media screen and (max-width: 640px) {
    padding: 1em;
  }
  .parent {
    &_title {
      h2 {
        margin: 0;
        padding-right: 2rem;
        word-wrap: break-word;
      }
      h4 {
        margin: 0px;
        opacity: 0.4;
      }
      position: relative;
      background: $background;
      padding: 4em;
      border-radius: 7px;
      margin-bottom: 60px;

      @media screen and (max-width: 640px) {
        padding: 2em 1em;
      }
    }
    &_children {
      h3,
      p {
        margin: 0;
      }
      &_item {
        margin-bottom: 1rem;
      }
    }
  }
}

.parent_children {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

/*
Only works on definitions that have been added by hand because they have a <p></p> attribute but causes trouble to all the preexisting ones since they are wrapped inside a div which is not having margin-top, bottom by default. All in all this line of code cannot be used.
.definition_description {
  margin-top: -16px;
  }
*/
  
.basketBtn {
  position: absolute;
  right: 45px;
  top: 80px;
  z-index: 2;

  @media screen and (max-width: 640px) {
    right: 17px;
    top: 40px;
  }
}
.definition_description ::v-deep p {
  margin: 0px;
}
</style>
